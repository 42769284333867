<template>
	<div class="tabbed-channels">
		<div class="live-programme__content is-small">
			<swiper v-if="filterSessionList.length > 0" :options="swiperOptions">
				<swiper-slide v-for="session in filterSessionList" :key="session.id" class="agenda__session">
					<div class="agenda__session__content" :class="{ 'is-striped': session.sponsored, 'is-live': !session.sponsored && isLive(session) }">
						<div v-if="isLive(session) && session.visitable === true && session.liveProgress" class="agenda__session__liveprogress">
							<div :style="{ width: `${session.liveProgress}%` }"></div>
						</div>
						<div class="agenda__session__time">
							<div>
								<p>
									<strong v-if="isLive(session) && session.visitable === true" class="agenda__session__livenow">Live</strong>
									<span
										>{{ session.start | dateTextFormat(session.date) }} &bull; {{ session.start | timeFormat(session.date) }} - {{ session.end | timeFormat(session.date) }}</span
									>
									CET-1
								</p>
							</div>
						</div>
						<h2>
							<router-link v-if="session.visitable === true" :to="{ name: 'AgendaSession', params: { url: session.url } }">
								<template v-if="session.title_regulated">{{ session.title_regulated }}</template>
								<template v-else>{{ session.title }}</template>
							</router-link>
							<span v-else>
								<template v-if="session.title_regulated">{{ session.title_regulated }}</template>
								<template v-else>{{ session.title }}</template>
							</span>
						</h2>
						<h3 v-if="session.session_type && session.session_type.name">{{ session.session_type.name }}</h3>

						<template v-if="!currentTrack || (currentTrack && session.live_track && currentTrack.id !== session.live_track.id)">
							<router-link
								v-if="session.external !== true && isLogged && session.live_track && session.live_track.url && isLive(session)"
								:to="{ name: 'LiveTrack', params: { url: session.live_track.url } }"
								class="agenda__status is-small is-live"
							>
								<span class="icon"><inline-svg :src="require('../assets/video-call.svg')" width="25"></inline-svg></span>
								<span>Join session</span>
							</router-link>
						</template>
						<a
							v-if="session.external === true && session.user_allowed === true && isLogged && isLiveExternal(session)"
							class="agenda__status is-small is-live"
							:href="session.external_url"
							target="_blank"
						>
							<span class="icon"><inline-svg :src="require('../assets/video-call.svg')" width="25"></inline-svg></span>
							<span>Join session</span>
						</a>
						<div v-if="session.speakers && session.speakers.length > 0" class="agenda__session__speakers">
							<router-link v-for="speaker in session.speakers" :key="speaker.id" :to="{ name: 'AgendaSpeaker', params: { url: speaker.url } }" class="agenda__session__speaker">
								<div class="image">
									<img v-if="speaker.profile_picture" :src="speaker.profile_picture | imageUrl('thumbnail')" />
									<img v-else src="@/assets/placeholder_speaker.jpg" />
								</div>
								<div>
									<h3>{{ speaker.firstName }} {{ speaker.lastName }}</h3>
									<h4>{{ speaker.country }}</h4>
								</div>
							</router-link>
						</div>
						<div v-if="isLogged" class="agenda__favourite" :class="{ 'is-favourited': session.favourite }">
							<a @click.prevent="toggleFavourite(session)">
								<inline-svg :src="require('../assets/calendar-plus.svg')" width="26"></inline-svg>
							</a>
						</div>
					</div>
				</swiper-slide>
				<span slot="button-prev" class="swiper-small-prev">
					<inline-svg :src="require('../assets/arrow.svg')" width="16"></inline-svg>
				</span>
				<span slot="button-next" class="swiper-small-next">
					<inline-svg :src="require('../assets/arrow.svg')" width="16"></inline-svg>
				</span>
			</swiper>
			<div v-else class="live-programme__content-empty">
				<div class="agenda__status is-full is-processing">
					<span class="icon"><inline-svg :src="require('../assets/info.svg')" width="25"></inline-svg></span>
					<span>No more sessions in this channel for today, please check the full agenda.</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http.js';
	import userSession from '../utils/session';
	import moment from 'moment-timezone';
	import DataCaptureModal from './DataCaptureModal';

	const debounce = (fn, delay) => {
		var timeoutID = null;
		return function() {
			clearTimeout(timeoutID);
			var args = arguments;
			var that = this;
			timeoutID = setTimeout(function() {
				fn.apply(that, args);
			}, delay);
		};
	};

	export default {
		name: 'TrackUpcoming',
		props: {
			currentTrack: {
				type: Object,
				required: true,
			},
		},
		data() {
			return {
				programme: {},
				dates: [],
				sessionList: [],
				currentDate: null,
				currentList: false,
				userSession: userSession.state,
				title: 'Live Programme',
				subtitle: '',
				background: '',
				swiperOptions: {
					slidesPerView: 1,
					spaceBetween: 10,
					allowTouchMove: true,
					navigation: {
						nextEl: '.swiper-small-next',
						prevEl: '.swiper-small-prev',
					},
					breakpoints: {
						1024: {
							slidesPerView: 2,
							spaceBetween: 30,
							allowTouchMove: false,
						},
					},
				},
				refreshInterval: null,
				dataCaptured: [],
				evaluated: [],
				modalOpened: false,
			};
		},
		computed: {
			allCount() {
				if (!this.programme && !this.currentDate) return false;
				if (!this.programme[this.currentDate]) return false;
				return this.programme[this.currentDate].length;
			},
			favCount() {
				if (!this.programme && !this.currentDate) return false;
				if (!this.programme[this.currentDate]) return false;
				return this.programme[this.currentDate].filter(e => e.favourite).length;
			},
			isLogged() {
				return this.userSession.loggedIn;
			},
			backgroundUrl() {
				return this.background && this.background.url ? `url('${this.$options.filters.imageUrl(this.background)}')` : null;
			},
			filterSessionList() {
				return this.sessionList.filter(session => session.live_track && session.live_track.id === this.currentTrack.id);
			},
		},
		beforeDestroy() {
			if (this.refreshInterval) {
				window.clearInterval(this.refreshInterval);
			}
		},
		mounted() {
			$http.all([$http.get(`/event-programme`)]).then(
				$http.spread(data => {
					this.setData(data);
				})
			);
			this.refreshInterval = window.setInterval(() => {
				this.refreshLiveProgress();
			}, 30 * 1000);
		},

		methods: {
			setData(data) {
				let sessions = data.data;
				sessions = sessions.reduce(function(r, a) {
					r[a.date] = r[a.date] || [];
					r[a.date].push(a);
					return r;
				}, Object.create(null));
				this.dates = Object.keys(sessions).sort((a, b) => (new Date(a) > new Date(b) ? 1 : new Date(a) < new Date(b) ? -1 : 0));
				this.currentDate = this.dates.length > 0 ? this.dates[0] : null;

				let dateSwitch = window.location.hash;
				if (dateSwitch) {
					let day = dateSwitch.split('-');
					if (day && day[1] && parseInt(day[1])) {
						day = parseInt(day[1]) - 1;
						this.currentDate = this.dates.length >= day ? this.dates[day] : this.dates[0];
					}
				} else {
					if (this.dates.length > 0) {
						let now = moment().tz('CET');

						let index = this.dates.findIndex(e => {
							return now.isSame(moment(e, 'YYYY-MM-DD', 'CET'), 'day');
						});
						if (index > 0) {
							this.currentDate = this.dates[index];
						}
					}
				}

				Object.keys(sessions).map(key => {
					sessions[key].sort((a, b) => (a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0));
					sessions[key].sort((a, b) =>
						moment(`${a.date} ${a.start}`).toDate() > moment(`${b.date} ${b.start}`).toDate()
							? 1
							: moment(`${a.date} ${a.start}`).toDate() < moment(`${b.date} ${b.start}`).toDate()
							? -1
							: 0
					);
					sessions[key] = sessions[key].filter(e => this.isVisible(e));

					sessions[key].map(e => {
						if (e.session_type && e.session_type.name) {
							e.searchType = e.session_type.name;
						}

						if (e.speakers && e.speakers.length > 0) {
							e.searchSpeakers = '';
							e.speakers.map(s => {
								e.searchSpeakers += s.name;
								return s;
							});
						}
						return e;
					});
				});

				this.programme = sessions;
				this.sessionList = this.programme[this.currentDate];
				this.sessionList.map(e => {
					if (e.speakers) {
						e.speakers = e.speakers.sort((a, b) => (a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0));
					}
					return e;
				});
				this.refreshLiveProgress();
			},
			selectDate(date) {
				this.currentDate = date;
				this.sessionList = this.currentList ? this.programme[this.currentDate].filter(e => e.favourite) : this.programme[this.currentDate];
				this.sessionList.map(e => {
					if (e.speakers) {
						e.speakers = e.speakers.sort((a, b) => (a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0));
					}
					return e;
				});
			},
			toggleFavourite(session) {
				session.favourite = !session.favourite;
				$http.put(`/event-programme-favourite/${session.id}`).then(
					() => {},
					() => {
						session.favourite = !session.favourite;
					}
				);
			},
			selectList(fav) {
				if (fav && !this.isLogged) return;
				this.currentList = fav;
				this.sessionList = fav ? this.programme[this.currentDate].filter(e => e.favourite) : this.programme[this.currentDate];
			},
			isLive({ date, start, end }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'Europe/Lisbon');
				let dEnd = moment.tz(`${date} ${end}`, 'Europe/Lisbon');
				let now = moment().tz(target);

				return now.isBetween(dStart, dEnd);
			},
			isLiveExternal({ date, start, end }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'Europe/Lisbon').subtract(15, 'minutes');
				let dEnd = moment.tz(`${date} ${end}`, 'Europe/Lisbon');
				let now = moment().tz(target);

				return now.isBetween(dStart, dEnd);
			},
			isEval({ date, end }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${end}`, 'Europe/Lisbon').subtract(5, 'minutes');
				let dEnd = moment.tz(`${date} ${end}`, 'Europe/Lisbon');
				let now = moment().tz(target);

				return now.isBetween(dStart, dEnd);
			},
			isVisible({ date, end }) {
				let target = moment.tz.guess();
				let dEnd = moment.tz(`${date} ${end}`, 'Europe/Lisbon');
				let now = moment().tz(target);

				return now.isBefore(dEnd);
			},
			calculateLiveProgress({ date, start, end }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'Europe/Lisbon');
				let dEnd = moment.tz(`${date} ${end}`, 'Europe/Lisbon');
				let now = moment().tz(target);

				let total = moment.duration(dEnd.diff(dStart)).asMinutes();
				let current = moment.duration(now.diff(dStart)).asMinutes();

				return Math.ceil((current * 100) / total);
			},
			refreshLiveProgress() {
				if (this.filterSessionList) {
					let block = false;

					for (let i in this.filterSessionList) {
						if (this.isLive(this.filterSessionList[i])) {
							this.$set(this.filterSessionList[i], 'liveProgress', this.calculateLiveProgress(this.filterSessionList[i]));

							if (this.isEval(this.filterSessionList[i])) {
								if (this.evaluated.indexOf(this.filterSessionList[i].id) === -1) {
									this.evaluated.push(this.filterSessionList[i].id);
									this.$emit('evaluate');
								}
							}

							if (this.filterSessionList[i].event_fees_blacklisted && this.filterSessionList[i].event_fees_blacklisted.length > 0 && this.userSession.user.event_fee) {
								let userFeeId = this.userSession.user.event_fee.id;
								if (userFeeId) {
									let sessionFeeIdCheck = this.filterSessionList[i].event_fees_blacklisted.find(e => e.id === userFeeId);
									if (sessionFeeIdCheck) {
										block = true;
									}
								}
							}

							if (this.modalOpened === false && this.filterSessionList[i].data_capture_enabled === true) {
								if (this.dataCaptured.indexOf(this.filterSessionList[i].id) === -1) {
									$http.get(`/user-agreements/${this.filterSessionList[i].id}`).then(
										() => {
											this.dataCaptured.push(this.filterSessionList[i].id);
										},
										() => {
											this.modalOpened = true;
											this.$modal.show(
												DataCaptureModal,
												{
													disclaimer: this.filterSessionList[i].data_capture_disclaimer,
												},
												{
													classes: 'data-capture-modal',
													width: '90%',
													maxWidth: 820,
													height: 'auto',
													adaptive: true,
													clickToClose: false,
												},
												{
													'before-close': ({ params }) => {
														this.modalOpened = false;
														$http
															.post('/user-agreements', {
																session: this.filterSessionList[i].id,
																choice: params,
															})
															.then(() => {
																this.dataCaptured.push(this.filterSessionList[i].id);
															});
													},
												}
											);
										}
									);
								}
							}
						} else {
							this.$delete(this.filterSessionList[i], 'liveProgress');
						}
					}

					this.$emit('fees', block);
				}
			},
		},
	};
</script>
